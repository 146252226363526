import React from 'react'
import styled, { ThemeProvider } from 'styled-components'
import Carousel from '../Carousel'
import Button from '../Button'

import { dark } from "../../styles/Themes";

const Section = styled.section`
  min-height: 100vh;
  width: 100%;
  background-color: ${props => props.theme.text};
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`
const Container = styled.div`
  width: 75%;
  min-height: 80vh;
  margin: 0 auto; 

  display: flex;
  justify-content: center;
  align-items: center;

  @media(max-width: 70em){
    width: 85%;
  }
  @media(max-width: 64em){
    width: 100%;
    flex-direction: column;

    &>*:last-child{
      width: 80%;
    }
  }
  @media(max-width: 40em){
    &>*:last-child{
      width: 90%;
    }
  }
`
const Box = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  min-height: 65vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media(max-width: 40em){
    min-height: 50vh;
  }
`
const Title = styled.h2`
  font-size: ${props => props.theme.fontxxl};
  text-transform: capitalize;
  width: 80%;
  color: ${props => props.theme.body};
  align-self: flex-start;
  margin: 0 auto;

  @media(max-width: 64em){
    width: 100%;
    text-align: center;
  }
  @media(max-width: 48em){
    font-size: ${props => props.theme.fontxl};
  }
  @media(max-width: 30em){
    font-size: ${props => props.theme.fontlg};
  }
`
const SubText = styled.p`
  font-size: ${props => props.theme.fontlg};
  width: 80%;
  color: ${props => props.theme.body};
  align-self: flex-start;
  margin: 1rem auto;
  font-weight: 400;

  @media(max-width: 64em){
    width: 100%;
    text-align: center;
    font-size: ${props => props.theme.fontmd};
  }
  @media(max-width: 30em){
    font-size: ${props => props.theme.fontsm};
  }
`
const SubTextLight = styled.p`
  font-size: ${props => props.theme.fontmd};
  width: 80%;
  color: ${props => `rgba(${props.theme.bodyRgba}, 0.6)`};
  align-self: flex-start;
  margin: 1rem auto;
  font-weight: 400;

  @media(max-width: 64em){
    width: 100%;
    text-align: center;
    font-size: ${props => props.theme.fontsm};
  }

  @media(max-width: 30em){
    font-size: ${props => props.theme.fontxs};
  }
`
const ButtonContainer = styled.div`
  width: 80%;
  margin: 1rem auto;
  display: flex;
  align-self: flex-start;

  @media(max-width: 64em){
   width: 100%;
   button{
    margin: 0 auto;
   }
  }
`

const About = () => {
  return (
    <Section id="about">
      <Container>
        <Box> 
          <Carousel />
        </Box>
        <Box>
          <Title>
            Welcome To The <br /> Tendsetter.
          </Title>
          <SubText>
          "At Tendsetters, we believe in turning influence into tangible rewards. We are the pioneers of the first Social Currency Card designed exclusively for #Tendsetters on Instagram."          </SubText>
          <SubTextLight>
          Imagine a world where your social impact earns you more than just likes – welcome to Tendsetters, where your influence is your currency.          </SubTextLight>
          <ButtonContainer>
            <ThemeProvider theme={dark}>
            <a href="https://www.instagram.com/tend.setters/" target={'_blank'} rel="noopener">
              
              <Button text='JOIN US ON INSTAGRAM'/>

            </a>
            </ThemeProvider>     
          </ButtonContainer>
        </Box>
      </Container>
    </Section>
  )
}

export default About
