import React from 'react'
import styled from 'styled-components'
import Banner from './Banner'
import Logo from './Logo'

import Facebook from '../Icons/Facebook'
import Instagram from '../Icons/Instagram'
import Twitter from '../Icons/Twitter'
import LinkedIn from '../Icons/LinkedIn'

const IconList = styled.div`
  display: flex;
  align-items: center;
  margin: 1rem auto;

  &>*{
    padding-right: 0.5rem;
    transition: all .2s;

    &:hover{
      transform: scale(1.2);
    }
  }
`

const Section = styled.section`
  min-height: 100vh;
  width: 100vw;
  background-color: ${props => props.theme.body};
  position: relative;
  color: ${props => props.theme.text};

  display: flex;
  flex-direction: column;
`
const Container = styled.div`
  width: 75%;
  margin: 2rem auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${props => props.theme.text};

  @media(max-width: 48em){
    width: 90%;
    h1{
      font-size: ${props => props.theme.fontxxxl};
    }
  }
`
const Left = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media(max-width: 48em){
    width: 100%;
  }
`
const MenuItems = styled.ul`
  list-style: none;
  width: 50%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-gap: 1rem;
  @media(max-width: 48em){
    display: none;
  }
`
const Item = styled.li`
  width: fit-content;
  cursor: pointer;

  &::after{
    content: '';
    display: block;
    width: 0%;
    height: 2px;
    background-color: ${props => props.theme.text};
    transition: width .3s;
  }
  &:hover::after{
    width: 100%;
  }
`
const Bottom = styled.div`
  width: 75%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 1rem;
  @media(max-width: 48em){
    flex-direction: column;
    span + span {
      margin-top: 0.6rem;
    }
  }
`


const Footer = () => {

  const scrollTo = (id) => {
    let element = document.getElementById(id)
    element.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest"
    })
  }
  
  return (
    <Section>
      <Banner />
      <Container>
        <Left>
          <Logo />
          <IconList>
            <a href="https://www.facebook.com" target={'_blank'} rel="noopener">
              <Facebook />
            </a>
            <a href="https://www.instagram.com/tend.setters/" target={'_blank'} rel="noopener">
              <Instagram />
            </a>
            <a href="https://twitter.com/tendsetters" target={'_blank'} rel="noopener">
              <Twitter />
            </a>
            <a href="https://www.linkedin.com" target={'_blank'} rel="noopener">
              <LinkedIn />
            </a>
          </IconList>
        </Left>
        <MenuItems> 
          <Item onClick={() => scrollTo('home')}>Home</Item>
          <Item onClick={() => scrollTo('about')}>About</Item>
          <Item onClick={() => scrollTo('roadmap')}>Roadmap</Item>
          <Item onClick={() => scrollTo('showcase')}>Showcase</Item>
          <Item onClick={() => scrollTo('team')}>Team</Item>
          <Item onClick={() => scrollTo('faq')}>Faq</Item>
        </MenuItems>
      </Container>
      <Bottom>
        <span>
          &copy; {new Date().getFullYear()} Tendsetters Realm. All rights reserved.
        </span>
        <span>
        Images by <a href="https://www.freepik.com/free-vector/influencer-recording-new-video-design_8142073.htm#query=influencers%20with%20products%20like%20watch&position=4&from_view=search&track=ais&uuid=4df332a9-b09a-454e-ba01-7c9b4d2d58b5#position=4&query=influencers%20with%20products%20like%20watch">Freepik</a>        </span>
        <span>
          Made with &#10084; by RK.
        </span>
      </Bottom>
    </Section>
  )
}

export default Footer
