import React from 'react'
import styled from 'styled-components'

import img1 from "../../assets/Nfts/bighead.svg"; 
import img2 from "../../assets/Nfts/bighead-1.svg";
import img3 from "../../assets/Nfts/bighead-2.svg";
import img4 from "../../assets/Nfts/bighead-3.svg";
import img5 from "../../assets/Nfts/bighead-4.svg";
import img6 from "../../assets/Nfts/bighead-5.svg";
import img7 from "../../assets/Nfts/bighead-6.svg";
import img8 from "../../assets/Nfts/bighead-7.svg";
import img9 from "../../assets/Nfts/bighead-8.svg";
import ConfettiComponent from '../Confetti';
import JoinUs from '../Joinus';

const Section = styled.section`
  min-height: 100vh;
  width: 100vw;
  background-color: ${props => props.theme.body};
  position: relative;
`

const Title = styled.h1`
  font-size: ${props => props.theme.fontxxl};
  text-transform: capitalize;
  color: ${props => props.theme.text};
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem auto;
  border-bottom: 2px solid ${props => props.theme.text};
  width: fit-content;

  @media(max-width: 64em){
    font-size: ${props => props.theme.fontxl};
  }
`
const Container = styled.div`
  width: 75%;
  margin: 2rem auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  @media(max-width: 64em){
    width: 80%;
  }
  @media(max-width: 64em){
    width: 90%;
    justify-content: center;
  }
`
const Item = styled.div`
  width: calc(20rem - 4vw);
  padding: 1rem 0;
  color: ${props => props.theme.body};
  margin: 2rem 1rem;
  position: relative;
  z-index: 5;
  backdrop-filter: blur(4px);

  border: 2px solid ${props => props.theme.text};
  border-radius: 20px;
  
  &:hover{
    img{
      transform: translateY(-2rem) scale(1.2);
    }
  }

  @media(max-width: 30em){
    width: 70vw;
  }
`

const ImageContainer = styled.div`
  width: 80%;
  padding: 1rem;
  margin: 0 auto;
  background-color: ${props => props.theme.carouselColor};
  border: 1px solid ${props => props.theme.text};

  border-radius: 20px;
  cursor: pointer;

  img{
    width: 100%;
    height: auto;
    transition: all .3s;

  }
`
const Name = styled.h2`
  font-size: ${props => props.theme.fontlg};
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  color: ${props => props.theme.text};
  margin-top: 1rem;
`
const Position = styled.h2`
  font-size: ${props => props.theme.fontmd};
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
  color: ${props => `rgba(${props.theme.textRgba}, 0.9)`};
  font-weight: 400;
`

const MemberComponent = ({img, name=" ", position=" "}) => {

  return(
    <Item>
      <ImageContainer>
        <img src={img} alt={name} />
      </ImageContainer>
      <Name>{name}</Name>
      <Position>{position}</Position>
    </Item>
  )
}

const Team = () => {
  return (
    <Section id="team">
      <ConfettiComponent />
      <Title>Team</Title>
      <Container>
        <MemberComponent img={img6} name="Raj K Patel" position="Founder" />
        <MemberComponent img={img2} name="Akash K Patel" position="Co-Founder" />
        <MemberComponent img={img1} name="Jalpa Patel" position="Director" />
        <MemberComponent img={img4} name="BLACK PANTHER" position="Manager" />
        <MemberComponent img={img5} name="DEATHSTROKE" position="Artist" />
        <MemberComponent img={img6} name="LAZY KONG" position="Social Media Manager" />
        <MemberComponent img={img7} name="CYBER PUNK" position="Blockchain Specialist" />
        <MemberComponent img={img8} name="MONK" position="Web3 Developer" />
        <MemberComponent img={img9} name="BANANA" position="Graphic Designer" />
        <JoinUs/>
      </Container>
    </Section>
  )
}

export default Team
