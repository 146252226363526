import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import React, { useLayoutEffect, useRef } from 'react'
import styled from 'styled-components'
import Accordion from '../Accordion'

const Section = styled.section`
  min-height: 100vh;
  width: 100vw;
  background-color: ${props => props.theme.text};
  position: relative;
  color: ${props => props.theme.body};

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const Title = styled.h1`
  font-size: ${props => props.theme.fontxxl};
  text-transform: uppercase;
  
  margin: 1rem auto;
  border-bottom: 2px solid ${props => props.theme.carouselColor};
  width: fit-content;

  @media(max-width: 48em){
    font-size: ${props => props.theme.fontxl};
  }
`
const Container = styled.div`
  width: 75%;
  margin: 2rem auto;
  display: flex;
  justify-content: space-between;
  align-content: center;
  @media(max-width: 64em){
    width: 80%;
  }
  @media(max-width: 48em){
    width: 90%;
    flex-direction: column;

    &>*:last-child{
      &>*:first-child{
      margin-top: 0;
    }
    }
  }
`

const Box = styled.div`
  width: 45%;
   & + &{
      margin-left: 15px;
  }
  @media(max-width: 64em){
    width: 90%;
    align-items: center;
     
  }
  @media(max-width: 48em){
    & + &{
      margin-left: 0;
    }
  }
`

const Faq = () => {

  const ref = useRef(null)
  gsap.registerPlugin(ScrollTrigger)

  useLayoutEffect(() => {
    let element = ref.current;
    
    ScrollTrigger.create({
      trigger: element,
      start: 'top top',
      end: 'bottom top',
      pin: true,
      pinSpacing: false,
      scrub: true,
    })

    return () => {
      ScrollTrigger.kill()
    }
  }, [])


  return (
    <Section ref={ref} id="faq">
      <Title>Faq</Title>
      <Container>
        <Box>
          <Accordion title='What is Tendsetters?'>
          Tendsetters: Is First Ever Social Currency Card connecting influencers and top brands. Earn up to 100% cashback based on your Tendscore – your unique social credit score!          </Accordion>
          <Accordion title='How to Apply for Tendsetter Card?'>
          To ensure a seamless application, meet the criteria: 1500+ followers, active engagement, and a public Instagram account. We Coming soon on Android! Click "Join Now" for effortless sign-up. Pre-register on our website to unlock exclusive offers and be the first to access irresistible deals!
</Accordion>
          <Accordion title='Can I use the Tendsetter Card only on the Tendsetters app?'>
          No limitations! Top up your Tendsetter Card with the desired balance, and you're free to use it anywhere, just like a regular debit card. Shop hassle-free and enjoy the flexibility of your Tendsetter Card!          </Accordion>
        </Box>
        <Box>
          <Accordion title='What steps do I need to take to receive cashback?'>
          If you meet the eligibility criteria, you'll receive your Tendsetter Card. Purchase products from our app, share your post on Instagram with the product, mentioning both the company and Tendsetters. Your cashback will be based on your Tendscore.
</Accordion>
          <Accordion title='What is Tendscore, and how is it calculated?'>
          Tendscore is your unique Social score on Tendsetters, calculated through our sophisticated algorithm. We analyze factors like your followers, reach, active shares, reshare frequency, and popularity metrics such as watch insights. Your Tendscore is a dynamic indicator, offering a comprehensive view of your influence, with a maximum score of 100.          </Accordion>
          <Accordion title='What if I dont meet the eligibility criteria for Tendsetter Card?'>
          If you currently don't meet the criteria, don't worry! We're actively working on ways to help you improve your followers and reach. Stay tuned for updates on how you can enhance your eligibility. Additionally, we are exploring opportunities to expand our platform to include influencers from other social media platforms with substantial reach.          </Accordion>
        </Box>
      </Container>
    </Section>
  )
}

export default Faq
