// JoinUs.js

import React from 'react';

const JoinUs = () => {
  return (
    <div>
        <a href="https://forms.visme.co/formsPlayer/6x3g1eo6-test-template" target="_blank" rel="noreferrer"/>
    </div>
  );
};

export default JoinUs;
