import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

const LogoText = styled.h1`
  font-family: 'Akaya Telivigala', cursive;
  font-size: ${props => props.theme.fontxxxl};
  color: ${props => props.theme.text};
  transition: all .2s; 
  &:hover{
    transform: scale(1.1);
  }

  @media(max-width: 64em){
    font-size: ${props => props.theme.fontxxl};
  }
`

const Logo = () => {
  return (
    <LogoText>
      <Link to='/'>
      <img
          src={require('../assets/logo.png')}
          alt="Logo"
          style={{ width: '145px', height: '130px' }} // Adjust the width as needed
        />
        
      </Link>
    </LogoText>
  )
}

export default Logo
